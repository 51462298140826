.dashboard {
  width: 400px;
  height: 275px;
}

#dashName h2 {
  font-size: 2.5rem;
}

.bet-card {
  width: 500px;
  height: 100px;
}

.name-odds {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.amount-winnings {
  display: flex;
  justify-content: space-between;
}

.dev-bet-card {
  width: 500px;
}
