.names {
  color: goldenrod;
  padding-bottom: 0.5rem;
}

/* TO DO */
/* 1. Add bets so that I can see what the other cards look like and the figure out how to have a certain amount of them show  */
/* 2. Figure out how to make them go vertically in columns */
/* *idea* Would it make more sense to paginate through them? Or should I see all of them at once? */

.allCardBets {
  width: 420px;
  max-height: 100px;
}

.text-span {
  display: flex;
  align-items: center;
  font-weight: 200;
  font-style: italic;
  justify-content: space-between;
  font-size: x-small;
  padding-left: 5px;
}

.bet-and-odds {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bet-and-odds h2 {
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
}

.wagered-and-won {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
}

h3 {
  font-size: medium;
}

.hit-bg {
  background-color: #00b300;
}

.push-bg {
  background-color: yellow;
}

.miss-bg {
  background-color: rgb(249, 27, 27);
}
