.ranking-card {
  width: 900px;
  height: 90px;
  margin-left: 6%;
  color: whitesmoke;
}

.horizontal-container {
  display: flex;
  align-items: center;
}

#cardRank {
  position: absolute;
  top: 17%;
  left: 5%;
}

#cardName {
  position: absolute;
  top: 17%;
  left: 15%;
}

#rankMoney {
  position: absolute;
  left: 36%;
  top: 25%;
}

#rankDifference {
  position: absolute;
  left: 60%;
  top: 25%;
}

.rankPercent {
  position: absolute;
  left: 85%;
  top: 28%;
}

.positive-percent {
  color: #00d10e;
  font-size: 30px;
}

.negative-percent {
  color: rgb(249, 27, 27);
  font-size: 30px;
}
