body {
  background-color: black;
}

.text-color {
  color: goldenrod;
}

.btn-primary {
  background-color: goldenrod;
  color: white;
  border-color: goldenrod;
  --bs-btn-active-bg: goldenrod;
  --bs-btn-active-border-color: goldenrod;
}

.btn-primary:hover {
  background-color: gold;
  border-color: goldenrod;
}

#weekNumber {
  color: goldenrod;
  margin-left: 6%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
#allBetsWeek {
  color: goldenrod;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 3rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow-x: auto;
}

.card-item {
  padding-right: 9px;
  margin-top: 10px;
}
